<template>
  <div
    class="flex flex-col"
    :class="rootElementClass"
  >
    <div class="overflow-x-auto">
      <div class="align-middle inline-block min-w-full">
        <div
          class="overflow-hidden"
          :class="tableContainerClass"
        >
          <table
            v-bind="attrs"
          >
            <slot name="tableHead">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="(colName, cIndex) in columns"
                    :key="'download-basket-table-head-column' + cIndex"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ colName }}
                  </th>
                </tr>
              </thead>
            </slot>
            <tbody>
              <slot name="tableBody">
                <tr
                  v-for="(tableRow, trIndex) in tableData"
                  :key="'tableRow' + trIndex"
                  :class="{
                    'even:bg-gray-100': striped
                  }"
                >
                  <td
                    v-for="(tdData, tdIndex) in (tableRow || [])"
                    :key="'tableData' + tdIndex"
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    {{ tdData }}
                  </td>
                </tr>
              </slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'BaseTable',

    inheritAttrs: false,

    props: {
        columns: {
            type: Array,
            default: () => [],
            description: 'Table Columns'
        },
        tableData: {
            type: Array,
            default: () => [],
            description: 'table body data'
        },
        striped: {
            type: Boolean,
            default: false,
            description: 'Changes color of even table rows for striped layout'
        },
        tableContainerClass: {
            type: [String, Object, Array],
            default: 'border-b border-gray-200 sm:rounded-lg shadow',
            description: 'classes applied to table container'
        },
        rootElementClass: {
            type: [String, Object, Array],
            default: '',
            description: 'classes applied to root element'
        }
    },

    setup (props, { attrs }) {
        return {
            attrs
        };
    }
};
</script>
